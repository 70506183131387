
import _ from 'lodash';
import React, { Component } from 'react';

import { FavoriteContext } from '.';


export default class FavoriteProvider extends Component {

  state = {
    items: [],
    maxNumber: 100
  };

  add = (data) => {
    let { items } = this.state;
    items.push(data.id);
    this.setState({
      items: items
    }, () => this.saveItems());
  };

  remove = (data) => {
    let { items } = this.state;
    const index = items.findIndex((item) => item === data.id);
    if (index !== -1) {
      items.splice(index, 1);
      this.setState({
        items: items
      }, () => this.saveItems());
    }
  };

  toggle = (data) => {
    if (this.exists(data.id)) {
      this.remove(data);
    }
    else {
      this.add(data);
    }
  };

  exists = (id) => {
    let { items } = this.state;
    return items.find((item) => item === id) !== undefined;
  };

  saveItems = () => {
    try {
      localStorage.setItem(`favorite-items`, JSON.stringify(this.state.items));
    }
    catch (e) {
      console.log(e);
    }
  };

  loadItems = () => {
    let value = localStorage.getItem('favorite-items');
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        if (_.isArray(parsedValue)) {
          return parsedValue;
        }
        else {
          localStorage.removeItem('favorite-items');
        }
      }
      catch (e) {
        localStorage.removeItem('favorite-items');
      }
    }
    return [];
  };

  componentDidMount() {
    this.setState({
      items: this.loadItems()
    })
  }

  render() {
    return (
      <FavoriteContext.Provider value={{
        state: this.state,
        items: this.state.items,
        add: this.add,
        toggle: this.toggle,
        exists: this.exists
      }}>
        {this.props.children}
      </FavoriteContext.Provider>
    );
  }
}
