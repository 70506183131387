
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar
} from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from 'styled-components';
import { FavoriteContext } from './index';

const StyledAddToFavorite = styled.span`
  display: inline-block;
  width: 16px;
  color: #6c757d;
  
  &:hover {
    cursor: pointer;
    color: #007bff;
  }
  
  ${props => props.exists && css`
    color: #ffc107;
  `}
`;


export default class AddToFavorite extends Component {
  render() {
    const { data } = this.props;
    return (
      <FavoriteContext.Consumer>
        {context => {
          const isExists = context.exists(data.id);
          const icon = isExists ? faStarSolid : faStar;

          return (
            <StyledAddToFavorite exists={isExists}>
              <FontAwesomeIcon
                width={18}
                icon={icon}
                onClick={() => context.toggle(data)}
              />
            </StyledAddToFavorite>
          )
        }}
      </FavoriteContext.Consumer>
    )
  }
}
