
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar
} from '@fortawesome/free-regular-svg-icons';
import Link from 'next/link';
import styled from 'styled-components';

import { FavoriteContext } from './index';

const FavoriteButton = styled(Button)`
  width: 60px;
  text-align: center;
  display: flex;
  
  svg {
    width: 20px;
    height: 20px;
    align-items: baseline;
  }
  
  span {
    margin-left: 5px;
  }
  
  &.btn-warning {
    color: #fff;
  }
`;


export default class FavoriteToolbar extends Component {
  render() {
    return (
      <FavoriteContext.Consumer>
        {context => {
          const length = context.items.length;
          return (
            <Link href={'/favorite'}>
              <FavoriteButton
                variant={length > 0 ? 'warning': 'light'}
              >
                <FontAwesomeIcon
                  icon={faStar}
                />
                <span>{length}</span>
              </FavoriteButton>
            </Link>
          )
        }}
      </FavoriteContext.Consumer>
    )
  }
}
