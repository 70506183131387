
import axios from 'axios';
import Qs from 'qs';


export const API_HOST = 'http://api-host:800/';
let CLIENT_API_HOST = '/api/';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  CLIENT_API_HOST = 'http://0.0.0.0:8000/api/';
}

export const serverApi = axios.create({
  baseURL: `${API_HOST}api/`,
  paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})
});

export const clientApi = axios.create({
  baseURL: CLIENT_API_HOST,
  paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})
});
