
import React, { Component } from 'react';
import { withRouter } from 'next/router';

export const AppContext = React.createContext();


class AppProvider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      classifiers: this.props.classifiers
    };
  }

  setClassifiers = (classifiers) => {
    this.setState({
      classifiers: classifiers
    })
  };

  getCurrentClassifier = () => {
    const { classifiers } = this.state;
    let path = this.props.router.asPath;
    path = path.substring(1);
    const pieces = path.split('/');
    if (pieces.length > 0) {
      const slug = pieces[0];
      return classifiers.find((item) => item.slug === slug);
    }
  };

  render() {
    return (
      <AppContext.Provider value={{
        classifiers: this.state.classifiers,
        setClassifiers: this.setClassifiers,
        getCurrentClassifier: this.getCurrentClassifier
      }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default withRouter(AppProvider);
